/* External css for creating the gradient for the home button like Link */
.btn {
  padding: 10px 20px;
  color: white;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  transition: transform 0.2s;
}

.btn:hover {
  transform: scale(1.05);
}

.instagram-gradient {
  background: linear-gradient(90deg, #f49a39, #e8580c);
}

.tiktok-gradient {
  background: linear-gradient(90deg, #f49a39, #f21799);
}
